<template>
  <v-app>
    <v-card
      class="mx-auto overflow-hidden rounded-0"
      height="100%"
      width="100%"
    >
      <v-app-bar color="primary" dark prominent height="55%">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" absolute temporary>
        <v-list>
          <v-list-item class="px-2">
            <v-list-item-avatar :color="color">
              <v-img :src="profileImage"></v-img>
            </v-list-item-avatar>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title class="text-h6"> </v-list-item-title>
              <v-list-item-subtitle>{{ full_name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list nav dense>
          <v-list-item-group active-class="deep-purple--text text--accent-4">
            <router-link
              to="/home"
              style="text-decoration: none"
              class="ma-1"
              color="secondary"
              width="100%"
            >
              <v-list-item>
                <v-icon class="mr-3">mdi-home</v-icon> Home
              </v-list-item>
            </router-link>

            <router-link
              to="/input"
              style="text-decoration: none"
              class="ma-1"
              color="secondary"
            >
              <v-list-item>
                <v-icon class="mr-3">mdi-pencil</v-icon> Input
              </v-list-item>
            </router-link>

            <router-link
              to="/graph"
              style="text-decoration: none"
              class="ma-1"
              color="secondary"
            >
              <v-list-item>
                <v-icon class="mr-3">mdi-chart-areaspline</v-icon> Graph
              </v-list-item>
            </router-link>

            <router-link
              to="/settings"
              style="text-decoration: none"
              class="ma-1"
              color="secondary"
            >
              <v-list-item>
                <v-icon class="mr-3">mdi-account-settings</v-icon> Settings
              </v-list-item>
            </router-link>

            <router-link
              to="/events"
              style="text-decoration: none"
              class="ma-1"
              color="secondary"
            >
              <v-list-item>
                <v-icon class="mr-3">mdi-calendar-plus</v-icon> Events
              </v-list-item>
            </router-link>

            <v-list-item @click="logout">
              <v-icon class="mr-3">mdi-logout</v-icon> Logout
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <v-main class="fullSize">
        <router-view></router-view>
      </v-main>
    </v-card>
  </v-app>
</template>

<script>
import firebase from "firebase";
import { store } from "./store";
import "firebase/firestore";

export default {
  name: "App",
  data: () => ({
    auth: false,
    authenticated: false,
    drawer: false,
    mini: true,

    small_name: null,
    color: null,
    full_name: null,
    profileImage: null,

    loadingDone: true,
  }),
  computed: {
    authenticate() {
      return store.getters.getAuthentication;
    },
  },
  watch: {
    authenticate(authState) {
      console.log(authState);
      this.auth = authState;
    },
  },
  created: function () {
    this.authenticated = localStorage.getItem("Authenticated");
    this.getUserDetails();
  },

  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          alert("Successfully logged out");
          localStorage.setItem("Authenticated", false);
          store.commit("authenticated", false);
          localStorage.removeItem("Authenticated");
          this.$router.push("/login");
        })
        .catch((error) => {
          alert(error.message);
          this.$router.push("/login");
        });
    },
    getUserDetails() {
      var _self = this;
      var tutorialsRef = firebase
        .firestore()
        .collection("users")
        .doc(localStorage.getItem("userID"))
        .collection("profile");
      tutorialsRef.get().then(function (snapshot) {
        snapshot.forEach((doc) => {
          _self.small_name = doc.data().small_name;
          _self.color = doc.data().color;
          _self.full_name = doc.data().full_name;
          _self.profileImage = doc.data().imageUrl;
          var imageRef = firebase.storage().ref().child(_self.profileImage);
          imageRef
            .getMetadata()
            .then((metadata) => {
              _self.profileImage = metadata.downloadURLs[0];
            })
            .catch((error) => {
              console.log(error);
            });
        });
      });
    },
  },
};
</script>
<style>
.fullSize {
  height: 90% !important;
}
</style>
