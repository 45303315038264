import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#607d8b",
        secondary: "#547A8D",
        anchor: "#8c9eff",
      },
    },
  },
});

export default vuetify;
