import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDgipqEfoHjHHy5ckOm4z4HN76iCj3fW1c",
  authDomain: "numberworkz.firebaseapp.com",
  projectId: "numberworkz",
  storageBucket: "numberworkz.appspot.com",
  messagingSenderId: "1031468825764",
  appId: "1:1031468825764:web:4a6d8c9f025134d51fba31",
  measurementId: "G-MN2LGB6ZQC",
};

firebase.initializeApp(firebaseConfig);

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
