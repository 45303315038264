import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase";
// import {store} from '../store'

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginPage.vue"),
  },
  {
    path: "/input",
    name: "InputPage",
    component: () =>
      import(/* webpackChunkName: "input" */ "../views/InputPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/graph",
    name: "GraphPage",
    component: () =>
      import(/* webpackChunkName: "graph" */ "../views/GraphPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/home",
    name: "HomePage",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomePage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "SettingsPage",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/SettingsPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/events",
    name: "EventPage",
    component: () =>
      import(/* webpackChunkName: "events" */ "../views/EventPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/*",
    name: "404_Not_Found",
    component: () =>
      import(/* webpackChunkName: "404" */ "../views/404Page.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  const auth = localStorage.getItem("Authenticated");

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !(await firebase.getCurrentUser())) {
    next("/login");
  } else if (to.path == "/login" && auth) {
    next("/input");
  } else if (to.path == "/") {
    next("/home");
  } else {
    next();
  }
});

export default router;
