import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    authenticated: false,
  },
  mutations: {
    authenticated(state, authState) {
      state.authenticated = authState;
    },
  },
  getters: {
    getAuthentication: (state) => {
      //console.log(state.authenticated); // this is ok!!
      return state.authenticated;
    },
  },
  actions: {},
  modules: {},
});
